import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getMe, getRepair } from "../../api";
import { IRepairDetail, IUser } from "../../types";
import RepairDetailSkeleton from "../../components/Repair/RepairDetailSkeleton";
import { Badge, Box, Button, Heading, HStack, Stack } from "@chakra-ui/react";
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import Footer from "../../components/PDF/Repair/Footer";
import Header from "../../components/PDF/Repair/Header";
import { VscFilePdf } from "react-icons/vsc";
import { useMemo } from "react";
import Header2 from "../../components/PDF/Repair/Header2";



Font.register({
    family: 'Noto Sans',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2',
      },
      {
        src: 'https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2',
        fontWeight: 'bold',
      },
    ],
  });
const styles = StyleSheet.create({
    body: {
    backgroundColor: '#ffffff',
    fontFamiy: 'Noto Sans',
    fontSize: 12,
    lineHeight: 1.4,
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 32,
    height: '100vh',
    },
    top: {
        flex: 1,
    },
    middle1: {
        flex: 3,
        fontFamiy: 'Noto Sans',

        justifyContent: 'flex-start',
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 20,
        paddingLeft: 20,
    },
    bottom: {
        justifyContent: "flex-end",
        flex: 1,
    },
})

export default function RepairDetail() {
    const { repairPk } = useParams();
    const navigate = useNavigate();

    const { isLoading, data } = useQuery<IRepairDetail>([`repairs`, repairPk], getRepair);
    const { data: userData } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const qid = data?.repair_id!

    const MyPDF = () => (
        <Document>
            <Page style={styles.body} wrap>
                {/*
                <View style={styles.top}>
                    <Header repair_id={qid}></Header>
                    <Header2 repair_id={qid}></Header2>
                </View>
                */}
                <View>
                <Text>{data?.submitter.name}</Text>
                <Text>{data?.client.name}</Text>
                <Text>Submitter: {data?.submitter.role}</Text>
                <Text>Submission Date: {data?.submission_date}</Text>
                <Text>Requester Name: {data?.submitter.name}</Text>
                <Text>Requester Email: {data?.submitter.email}</Text>
                <Text>Client Name: {data?.client.name}</Text>
                <Text>Dr Name: {data?.client.clinic}</Text>
                <Text>Client Code: {data?.client.abn}</Text>
                <Text>Sales Date: {data?.sales_date}</Text>
                <Text>Received Date from Clinic: {data?.received_date}</Text>
                <Text>Manufacturer: {data?.manufacturer}</Text>
                <Text>Product Name: {data?.product_name}</Text>
                <Text>Serial Number: {data?.serial_number}</Text>
                <Text>Composition List: {data?.composition_list}</Text>
                <Text>Under Warranty: {data?.kind}</Text>
                <View style={{fontFamily: "Noto Sans"}}>
                <Text>Details of requested work and/or description of problem: {data?.whyrepair}</Text>
                </View>
                <Text>Reference Number: {data?.repair_id}</Text>
                </View>


{/*  */}
                <View style={styles.middle1}>
                    {/*
                    <View style={{display: "flex", flexDirection: "row", paddingLeft: "20px"}}>
                        <View style={{flex: "1",}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <View style={{flex: "1", justifyContent: "flex-start"}}>
                                    <Text style={{fontSize: "10px"}}>~</Text>
                                </View>
                                <View style={{flex: "10",}}>
                                    <Text style={{fontSize: "8px"}}>Repair Number:</Text>
                                    <Text style={{fontSize: "13px"}}>{data?.repair_id}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{flex: "1", marginLeft: "40px"}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <View style={{flex: "1", justifyContent: "flex-start"}}>
                                    <Text style={{fontSize: "10px"}}>~</Text>
                                </View>
                                <View style={{flex: "10",}}>
                                    <Text style={{fontSize: "8px"}}>Submission Date:</Text>
                                    <Text style={{fontSize: "13px"}}>{data?.submission_date}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Text style={{width: "100%", border: "0.5px solid #f0f0f0", backgroundColor: "#f0f0f0", marginTop: "7px", marginBottom: "7px"}}></Text>
                    <View style={{display: "flex", flexDirection: "row", paddingLeft: "20px"}}>
                        <View style={{flex: "1",}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <View style={{flex: "1", justifyContent: "flex-start"}}>
                                    <Text style={{fontSize: "10px"}}>~</Text>
                                </View>
                                <View style={{flex: "10",}}>
                                    <Text style={{fontSize: "8px"}}>Service Request Product:</Text>
                                    <Text style={{fontSize: "13px"}}>{data?.product_name}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{flex: "1", marginLeft: "40px"}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <View style={{flex: "1", justifyContent: "flex-start"}}>
                                    <Text style={{fontSize: "10px"}}>~</Text>
                                </View>
                                <View style={{flex: "10",}}>
                                    <Text style={{fontSize: "8px"}}>Manufacturer:</Text>
                                    <Text style={{fontSize: "13px"}}>{data?.manufacturer}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Text style={{width: "100%", border: "0.5px solid #f0f0f0", backgroundColor: "#f0f0f0", marginTop: "7px", marginBottom: "7px"}}></Text>
                    <View style={{display: "flex", flexDirection: "row", paddingLeft: "20px"}}>
                        <View style={{flex: "1",}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <View style={{flex: "1", justifyContent: "flex-start"}}>
                                    <Text style={{fontSize: "10px"}}>~</Text>
                                </View>
                                <View style={{flex: "22"}}>
                                    <Text style={{fontSize: "8px"}}>Request Name (Sales Rep):</Text>
                                    <Text style={{fontSize: "13px"}}>{data?.submitter.name}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                     */}
                    <Text style={{width: "100%", border: "1.5px solid #0765A3", backgroundColor: "#0765A3", marginTop: "12px", marginBottom: "12px"}}></Text>
                    <View>
                        <View style={{display: "flex", flexDirection: "row", paddingLeft: "20px"}}>
                            <View style={{flex: "1",}}>
                                <View style={{display: "flex", flexDirection: "row"}}>
                                    <View style={{flex: "1", justifyContent: "flex-start"}}>
                                        <Text style={{fontSize: "10px"}}>~</Text>
                                    </View>
                                    <View style={{flex: "10",}}>
                                        {/* <Text style={{fontSize: "8px"}}>Repair Number:</Text> */}
                                        <Text style={{fontSize: "13px"}}>{data?.repair_id}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{flex: "1", marginLeft: "40px"}}>
                                <View style={{display: "flex", flexDirection: "row"}}>
                                    <View style={{flex: "1", justifyContent: "flex-start"}}>
                                        <Text style={{fontSize: "10px"}}>~</Text>
                                    </View>
                                    <View style={{flex: "10",}}>
                                        {/* <Text style={{fontSize: "8px"}}>Submission Date:</Text> */}
                                        <Text style={{fontSize: "13px"}}>{data?.submission_date}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <Text style={{width: "100%", border: "1.5px solid #0765A3", backgroundColor: "#0765A3", marginTop: "12px", marginBottom: "12px"}}></Text>

                    <View>
                        <View style={{display: "flex", flexDirection: "row", paddingLeft: "20px"}}>
                            <View style={{flex: "1",}}>
                                <View style={{display: "flex", flexDirection: "row"}}>
                                    <View style={{flex: "1", justifyContent: "flex-start"}}>
                                        <Text style={{fontSize: "10px"}}>~</Text>
                                    </View>
                                    <View style={{flex: "10",}}>
                                        <Text style={{fontSize: "8px"}}>Product Received date from Clinic</Text>
                                        <Text style={{fontSize: "11px"}}>{data?.submission_date}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{flex: "1", marginLeft: "40px"}}>
                                <View style={{display: "flex", flexDirection: "row"}}>
                                    <View style={{flex: "1", justifyContent: "flex-start"}}>
                                        <Text style={{fontSize: "10px"}}>~</Text>
                                    </View>
                                    <View style={{flex: "10",}}>
                                        <Text style={{fontSize: "8px"}}>Under Warranty?</Text>
                                        <Text style={{fontSize: "11px"}}>{data?.kind}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>


                    <View style={{display: "flex", flexDirection: "row", paddingLeft: "20px"}}>
                        <View style={{flex: "1",}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <View style={{flex: "1", justifyContent: "flex-start"}}>
                                    <Text style={{fontSize: "10px"}}>~</Text>
                                </View>
                                <View style={{flex: "22"}}>
                                    <Text style={{fontSize: "8px"}}>Composition List that came with</Text>
                                    <Text style={{fontSize: "11px"}}>{data?.composition_list}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingLeft: "20px"}}>
                        <View style={{flex: "1",}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <View style={{flex: "1", justifyContent: "flex-start"}}>
                                    <Text style={{fontSize: "10px"}}>~</Text>
                                </View>
                                <View style={{flex: "22"}}>
                                    <Text style={{fontSize: "8px"}}>Details of requested work and/or description of problem</Text>
                                    <Text style={{fontSize: "11px", fontFamily: "Noto Sans"}}>{data?.whyrepair}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                
                <View style={styles.bottom}>
                    <Footer/>
                </View>
            </Page>
        </Document>
        )




/* ----------------
{data?.submitter.name}
{data?.client.name}
Submitter: {data?.submitter.role}
Submission Date: {data?.submission_date}
Requester Name: {data?.submitter.name}
Requester Email: {data?.submitter.email}
Client Name: {data?.client.name}
Dr Name: {data?.client.clinic}
Client Code: {data?.client.abn}
Sales Date: {data?.sales_date}
Received Date from Clinic: {data?.received_date}
Manufacturer: {data?.manufacturer}
Product Name: {data?.product_name}
Serial Number: {data?.serial_number}
Composition List: {data?.composition_list}
Under Warranty: {data?.kind}
Details of requested work and/or description of problem: {data?.whyrepair}
Reference Number: {data?.repair_id}
*/

    function CheckUser() {
        if(userData?.role === "Boss" || "TS Team" && data?.repair_id || userData?.role === "Sean Hyun" && data?.repair_id ) {
            return <>
                <HStack>
                <Link to={`/repairs/`}><Button>Go Back</Button></Link>
                <PDFDownloadLink document={<MyPDF />} fileName={`${data?.submitter.name}_${data?.repair_id}`}>
                {({loading}) => 
                    loading? (
                    <>Loading Document...</>
                    ) : (
                        <Button><VscFilePdf /></Button>
                    )
                }
                </PDFDownloadLink>
                </HStack>
                <Heading>{data?.submitter.name}</Heading>
                <Heading color="green">{data?.client.name}</Heading>
                <HStack><Box>Submitter: </Box><Badge fontSize={'xl'}>{data?.submitter.role}</Badge></HStack>
                <HStack><Box>Submission Date: </Box><Box>{data?.submission_date}</Box></HStack>
                <HStack><Box>Requester Name: </Box><Box>{data?.submitter.name}</Box></HStack>
                <HStack><Box>Requester Email: </Box><Box>{data?.submitter.email}</Box></HStack>
                <HStack><Box>Client Name: </Box><Box>{data?.client.name}</Box></HStack>
                <HStack><Box>Dr Name: </Box><Box>{data?.client.clinic}</Box></HStack>
                <HStack><Box>Client Code: </Box><Box>{data?.client.abn}</Box></HStack>
                <HStack><Box>Sales Date: </Box><Box>{data?.sales_date}</Box></HStack>
                <HStack><Box>Received Date from Clinic: </Box><Box>{data?.received_date}</Box></HStack>
                <HStack><Box>Manufacturer: </Box><Box>{data?.manufacturer}</Box></HStack>
                <HStack><Box>Product Name: </Box><Box>{data?.product_name}</Box></HStack>
                <HStack><Box>Serial Number: </Box><Box>{data?.serial_number}</Box></HStack>
                <HStack><Box>Composition List: </Box><Box>{data?.composition_list}</Box></HStack>
                <HStack><Box>Under Warranty: </Box><Box>{data?.kind}</Box></HStack>
                <HStack><Box>Details of requested work and/or description of problem: </Box><Box>{data?.whyrepair}</Box></HStack>
                <HStack><Box>Reference Number: </Box><Box>{data?.repair_id}</Box></HStack>
                </>
        } else if (userData?.role === "Sales Team" && data?.repair_id) {
            if(minimax_number === data?.submitter.minimax_number) {
                return <>
                <Heading>{data?.submitter.name}</Heading>
                <Heading color="green">{data?.client.name}</Heading>
                <HStack><Box>Submitter: </Box><Badge fontSize={'xl'}>{data?.submitter.role}</Badge></HStack>
                <HStack><Box>Submission Date: </Box><Box>{data?.submission_date}</Box></HStack>
                <HStack><Box>Requester Name: </Box><Box>{data?.submitter.name}</Box></HStack>
                <HStack><Box>Requester Email: </Box><Box>{data?.submitter.email}</Box></HStack>
                <HStack><Box>CLIENT NAME: </Box><Box>{data?.client.name}</Box></HStack>
                <HStack><Box>Dr Name: </Box><Box>{data?.client.clinic}</Box></HStack>
                <HStack><Box>Client Code: </Box><Box>{data?.client.abn}</Box></HStack>
                <HStack><Box>Sales Date: </Box><Box>{data?.sales_date}</Box></HStack>
                <HStack><Box>Received Date from Clinic: </Box><Box>{data?.client.abn}</Box></HStack>
                <HStack><Box>Manufacturer: </Box><Box>{data?.manufacturer}</Box></HStack>
                <HStack><Box>Product Name: </Box><Box>{data?.product_name}</Box></HStack>
                <HStack><Box>Serial Number: </Box><Box>{data?.serial_number}</Box></HStack>
                <HStack><Box>Received Date from Clinic: </Box><Box>{data?.client.abn}</Box></HStack>
                <HStack><Box>Composition List: </Box><Box>{data?.composition_list}</Box></HStack>
                <HStack><Box>Under Warranty: </Box><Box>{data?.kind}</Box></HStack>
                <HStack><Box>Details of requested work and/or description of problem: </Box><Box>{data?.whyrepair}</Box></HStack>
                <HStack><Box>Reference Number: </Box><Box>{data?.repair_id}</Box></HStack>
                </>
            } else {
                navigate("/")
            }
        } else {
            navigate("/")
        }
        return <Box>{data?.repair_id}</Box>
    }
    return (
        <Box>
        {isLoading ? (
            <>
            <RepairDetailSkeleton />
            </>
        ) : null }
            <Box>
                <CheckUser />
            </Box>
        </Box>
    )
}